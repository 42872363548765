.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;  
  justify-content: center; 
  background-color: rgba(0,0,0,0.5);
}

.modal-wrapper {
  margin-left: 16px ;
  margin-right: 16px ;

}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  margin:20px  auto;
  max-width: 700px;
  padding: 10px;
  border-radius: 8px;
  height: auto;

  
}

.modal-heade-r {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  font-weight: bold;
}

.icone {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  margin-right: 20px;
  float: right;
}

.icone:hover {
  color: red;
}

.modal-body {
  padding: 8px 0;
  overflow-y:auto;
  max-height: 470px;
  
}

.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0;  
}
